<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3 float-left">
										儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/DocTemplate/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">編號</label>
							<div class="col-sm-10">
								<input v-model="model.id"
									type="text"
									class="form-control"
									id="id"
									name="id"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="code" class="col-sm-2 col-form-label">代碼</label>
							<div class="col-sm-10">
								<input v-model="model.code"
									type="text"
									class="form-control"
									id="code"
									name="code"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="typeid" class="col-sm-2 col-form-label">分類</label>
							<div class="col-sm-10">
								<select v-model="model.typeid" class="form-control" id="typeid" name="typeid">
									<option v-for="category in templateCategories" :key="category.type" :value="category.type">
										{{ category.label }}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="title" class="col-sm-2 col-form-label">模板說明</label>
							<div class="col-sm-10">
								<input v-model="model.title"
									type="text"
									class="form-control"
									id="title"
									name="title"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="realfile" class="col-sm-2 col-form-label">上傳檔案</label>
							<div class="col-sm-10">
								<input type="file" name="file" id="doc" class="form-control" @change="handleFileChange" />
								<p color="blue">檔案沒異動, 可以免重新上傳</p>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="version" class="col-sm-2 col-form-label">列印份數</label>
							<div class="col-sm-3">
								<input v-model="model.printCount" type="number" class="form-control" id="printCount"
									name="printCount" />
							</div>
						</div>						
						<div class="form-group row">
							<label htmlFor="version" class="col-sm-2 col-form-label">版號</label>
							<div class="col-sm-10">
								<input v-model="model.version"
									type="text"
									class="form-control"
									id="version"
									name="version"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="enable" class="col-sm-2 col-form-label">啟用</label>
							<div class="col-sm-10">
								<input v-model="model.enable"
									type="checkbox"
									class="form-check-input"
									id="enable"
									name="enable"
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue';
	import { getDocTemplate, postDocTemplate } from '@/api/DocTemplate.js';
	import { fullListTemplateCategory } from '@/api/TemplateCategory.js';

	export default {
		name: 'DocTemplateEdit',
		components: {
			LayoutDiv,
		},
		data() {
			return {
				model: {
					id: '',
					code: '',
					typeid: Number,
					title: '',
					file: null,
					printCount: 1,
					version: '',
					enable: ''
				},
				isSaving: false,
				templateCategories: [] // 新增存放分類資料的陣列
			};
		},
		created() {
			const id = this.$route.params.id;

			this.getTemplateCategory();
			getDocTemplate(id).then(rep => {
				this.model = rep.data;
				// 確保 this.model.type 有值，若未定義則設為 null
				this.model.type = this.model.type || 0;
			});

		},
		methods: {
			handleFileChange(event) {
				this.model.file = event.target.files[0];
			},
			handleSave() {
				if (this.isSaving) return;

				this.isSaving = true;

				const formData = new FormData();
				Object.keys(this.model).forEach(key => {
					formData.append(key, this.model[key]);
				});

				postDocTemplate(formData).then(rep => {
					this.isSaving = false;
					return rep;
				})
				.catch(error => {
					this.isSaving = false;
					return error;
				});
			},
			getTemplateCategory() {
				fullListTemplateCategory().then(rep => {
					this.templateCategories = rep.data.items;
				});
			}
		},
	};
</script>